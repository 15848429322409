@import "global.css";
@import "incoming_call.css";
@import "person_dialog.css";
@import "contract.css";
@import "not_user_role.css";
@import "./ed-content.scss";
@import "scss/app";

/*email css*/
.send-message-container {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
}

.send-message-container > button:nth-child(1) {
    width: 85%;
}

