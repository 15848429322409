.Toolbars-wrapper {
  background-color: var(--primary-color);
  display: flex;

  @include device-type(mb) {
    padding: 10px;
    border-radius: 0 0 10px 10px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    .ant-btn {
      margin: 0 !important;
      width: 100%;
      text-align: left;
    }
    .toolbars-additions {
      text-align: center;
    }
  }
}

.Toolbars-button {
  margin-left: 10px;
}

.Toolbars-wrapper-row {
  display: flex;
  width: 100%;

  @include device-type(pc) {
    flex-direction: row;
    gap: 20px;

    .Toolbars-row-item {
      display: flex;
      gap: 20px;

      &:first-child {
        width: 100%;
      }
    }

    .Refbook-select {
      width: 200px;
    }
  }
  @include device-type(not-pc) {
    flex-direction: column;
    gap: 10px;

    .Toolbars-row-item {
      display: flex;
      gap: 10px;

      .Refbook-select {
        width: 100%;
      }
    }
  }
}

@include device-type(not-pc) {
  .ant-dropdown {
    z-index: 100;
  }
}
