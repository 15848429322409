:root {
    --primary-color: #0b7b7f;
    --primary-color-light: #12c0c4;
    --primary-color-scale: rgba(11, 123, 127, 0.35);
    --primary-color-scale-light: rgba(11, 123, 127, 0.55);
    --secondary-color: #e2f3f1;
    --accent-color: #ffffff;
    --default-border-color: #d4d4d4;
    --gray-scale-color: rgb(220, 220, 220);
    --background-gray-scale-color: rgba(234, 234, 234, 0.5);
    --blue-light: #6860f5;
    --mobile-bluelight-field-bg-color: #f4f9fd;
    --mobile-graydark-bg-color: #7c8491;
}

.Chat-CreateChatButton-wrapper-row {
    margin-top: 10px;
}

.ant-table-sticky-scroll {
    background: transparent;
    border: none;
}
.ant-table-sticky-scroll-bar {
    border-radius: 20px;
    background-color:rgba(255, 255, 255, 65%)
}

.ant-layout-sider::-webkit-scrollbar,
.leads-list::-webkit-scrollbar,
.pre-tasks::-webkit-scrollbar,
.chat-virtuoso-scroller::-webkit-scrollbar,
.task-planner::-webkit-scrollbar,
.notification-drawer-wrapper .notification-drawer-list::-webkit-scrollbar,
.site-layout-background::-webkit-scrollbar {
    width: 7px;
}

.ant-layout-sider::-webkit-scrollbar-track,
.leads-list::-webkit-scrollbar-track,
.pre-tasks::-webkit-scrollbar-track,
.chat-virtuoso-scroller::-webkit-scrollbar-track,
.task-planner::-webkit-scrollbar-track,
.notification-drawer-wrapper .notification-drawer-list::-webkit-scrollbar-track,
.site-layout-background::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 20px;
}

.ant-layout-sider::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color:rgba(255, 255, 255, 35%)
}

.leads-list::-webkit-scrollbar-thumb,
.pre-tasks::-webkit-scrollbar-thumb,
.chat-virtuoso-scroller::-webkit-scrollbar-thumb,
.task-planner::-webkit-scrollbar-thumb,
.notification-drawer-wrapper .notification-drawer-list::-webkit-scrollbar-thumb,
.site-layout-background::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: var(--primary-color-scale);
}

.Header-button-badge .ant-badge-dot {
    top: 25px !important;
    right: 10px !important;
}

.SwitchersWrapper {
    padding: 2px;
    background-color: var(--accent-color);
    border: 1px solid var(--default-border-color);
    border-radius: 10px;
}
.SwitchersWrapper > .MainTabSwitcher-RadioBtn {
    border: none;
    background-color: inherit;
    border-radius: 10px !important;
}
.SwitchersWrapper > .MainTabSwitcher-RadioBtn::before {
    display: none;
}

.ant-btn-default {
    border-radius: 10px;
}

.css-z2g85-MuiBackdrop-root {
    top: 64px !important;
}

.css-1vz6k3y-MuiModal-root-MuiDrawer-root {
    top: 64px !important;
}



.react-resizable-handle {
    position: absolute !important;
    right: -5px !important;
    bottom: 0 !important;
    z-index: 1 !important;
    width: 10px !important;
    height: 100% !important;
    cursor: col-resize !important;
}



.react-resizable-handle {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: col-resize;
}
.test-test th {
    height: 21px;
    max-height: 21px;
}

.ant-layout-sider {
    left: 0;
    top: 0;
    height: 100%;
    width: 243px !important;
    flex: 0 0 243px !important;
    max-width: 243px !important;
    min-width: 243px !important;
    overflow: auto;
}

.ant-layout-sider-collapsed {
    left: 0;
    top: 0;
    height: 100%;
    width: 80px !important;
    flex: 0 0 80px !important;
    max-width: 80px !important;
    min-width: 80px !important;
    overflow-x: hidden;
}
.ant-layout-header {
    line-height: 64px;
    position: relative;
    z-index: 1;
}



.ant-table-tbody>tr.ant-table-row-selected>td {
    background: #c0dcd9;
    border-color: rgba(0,0,0,.03);
}
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
    transition: background 0.4s;
    background-color: #c0dcd9;
}
.ant-table-small .ant-table-thead > tr > th {
    background-color: var(--primary-color) !important;
    border-bottom: 1px solid #d5e5e3 !important;
    color: #fff !important;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}
/* restet email editor styles */
.email-editor .ck.ck-content:not(.ck-comment__input *) {
    height: 250px;
    min-height: 150px;
    overflow-y: auto;
}
.email-editor .ck.ck-content:not(.ck-comment__input *)  > p {
    margin: 0;
}
.email-editor .ck .ck-content img {
    position:absolute !important;
}
.email-editor .ck.ck-editor__editable .image, .ck.ck-editor__editable .image-inline {
    position: absolute !important;
}
.email-container {
    box-shadow: 0 0 2px rgb(58 66 75 / 10%), 0 2px 4px rgb(58 66 75 / 20%);
    border-radius: 5px;
    background: #fff;
}

.image-abosluted img {
    position: absolute
}
.email-menu {
    padding: 10px;
    border-radius: 5px 0 0 5px;
    background: #f4f6f8;
    height: 100%;
}

.email-menu__list {
    margin-top: 20px;
}

.email-menu__list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    cursor: pointer;
    /*background: #efefef;*/
    padding: 10px;
    border-radius: 5px;
    font-size: 13px;
}

.stat-cart {
    margin-top: 15px;
}

.email-menu__list-item.active {
    font-weight: 600;
}

.email-menu__list-item.active, .email-menu__list-item:hover {
    background: #efefef;
}

.email-menu__list-item > div > span {
    font-size: 15px;
    margin-right: 5px;
}
.select-staff-stat {
    width: 200px !important;
    border-radius: 0 10px 10px 0 !important;
    border: none !important;
    box-shadow: none !important;
    z-index: 11 !important;
}
.select-staff-stat > div {
    border-radius: 0 10px 10px 0 !important;
    height: 30px !important;
    border: none !important;
    box-shadow: none !important;
    z-index: 11 !important;
}
.custom-picker {
    visibility: hidden;
    position: absolute;
    right: 86px;
    top: 5px
}
.drop-container {
    display: block;
    position: absolute;
}
.drop-container * li {
    height: 25px !important;
    clear: both;
    margin: 0;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: normal;
    font-size: 14px;
    line-height: 22px !important;
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.3s;
}
.hidden {
    display: none
}
.select-in-filters > span {
    box-shadow: none !important;
}
.email-messages {
    padding: 0px 10px 20px 10px;
}
.ant-table-filter-column {
    padding: 0 10px 0 10px !important;
}
.email-messages__item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f4f6f8;
    cursor: pointer;
    padding: 10px;
}

.email-messages__item.active, .email-messages__item:hover {
    background: #f4f6f8;
}

.email-messages__item-from {
    margin: 0 10px;
    padding-right: 30px;
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.small-dragger {
    height: 30px !important;
}

.ant-upload .ant-upload-btn {
    padding: 0 !important;
}

.email-message__item-subject {
    display: flex;
    align-items: center;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.email-draft-collapse .ant-collapse-header  {
 padding: 12px 0 !important;
}
.drop-container .ant-menu-item-active * {
    color: black !important;
}
.filter-me-label {
    color: black !important;
    font-weight: 500;
}
#filter-menu :hover {
    color: black;
}
.ant-menu {
    border-right: none !important;
    background: #0b7b7f;
}
.ant-menu-submenu-title {
    padding: 0 !important;
    color: black !important;
}

.sub-menu-item {
    height: 100% !important;
    width: 100% !important;
}

.ant-menu-sub {
    min-height: 100% !important;
    color: black !important;
    background: red;
}
.nav-item-sub-menu {
    color: black !important;
    font-weight: 400 !important;
    font-size: 14px !important;
}
.test {
    color: black !important;
    font-weight: 400 !important;
    font-size: 14px !important;
}
#facsimile {
    position: absolute;
}
#stamp {
    position: absolute;
}
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open
{
    background: white;
    color: #0b7b7f;
}

.sub-menu-element :hover {
    color: #0b7b7f !important;
}
.ant-menu-submenu {
    z-index: 9999999999 !important;
}
.ant-menu-submenu-popup {
    top: 0 !important;
    height: 100vh !important;
    position: fixed !important;
    z-index: 9999999999 !important;
}
.ant-menu > .ant-menu-sub {
    color: black !important;
    background: #c0dcd9;
    z-index: 9999999998 !important;
 }

.thread-btn {
    border: 1px solid #b1b1b1;
    color: #b1b1b1;
    border-radius: 5px;
    padding: 2px 5px;
    margin-right: 10px;
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: center;
}

.thread-btn:hover {
    border: 1px solid #878787;
    color: #878787;
}

.email-messages__item-from > span {
    margin-right: 5px;
}

.email-message__item-date {
    margin-left: auto;
    color: #a0a0a0;
    display: flex;
    align-items: center;
}

.email-body {
    padding: 20px;
}

.email-body > b {
    font-size: 20px;
}


.email-body blockquote {
    background: #f9f9f9;
    border-left: 1px solid #ccc;
    margin: 0;
    padding: 1em 0 0 10px;
  }
  .email-body  blockquote:before {
    color: #ccc;
    font-family: Arial;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }
  .email-body blockquote + blockquote {
    padding: 0 0 0 3.15em;
  }
  .email-body blockquote + blockquote:before {
    display: none;
  }
  .email-body blockquote:last-of-type {
    margin-bottom: 1em;
    padding-bottom: 0.5em;
  }
  .email-body blockquote p {
    /* display: inline; */
  }
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
    border-top-left-radius: 10px;
}
.ant-tabs-top > .ant-tabs-nav {
    margin: 0px;
}
.tab-panel-border-radius {
    border-radius: 10px
}
.tab-panel:hover {
    color: #1e1d1d !important;
    cursor: pointer;
}
.tab-panel-scroll {
    overflow-x: auto;
    overflow-y: hidden;
}
/*View CP div */
.view-CP {
    height: 1130px;
    max-height: 1130px !important;
    width: 735px;
    border: 3px solid #d3d3d3;
    overflow: hidden;
}
.shoulderForm .ant-form-item-explain.ant-form-item-explain-error {
    display: none !important;
}
.shoulderForm .ant-show-help-leave.ant-show-help-leave-active {
    display: none !important;
}

.email-toolbar {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #cecece;
    /*padding: 10px 15px;*/
    margin-bottom: 5px;
    margin-right: 10px;
}

.email-toolbar__button {
    font-size: 13px;
    padding: 10px 15px;
    font-weight: 500;
    cursor: pointer;
}

.email-toolbar__button > span {
    margin-right: 5px;
}

.email-toolbar__button:hover {
    background: #f4f6f8;
}

.email-toolbar__button.disabled {
    opacity: .3;
    pointer-events: none;
}

.collapse-email .ant-collapse-header {
    padding: 0 !important;
    color: #a0a0a0 !important;
}

.email-flag {
    display: flex;
    align-items: center;
    /* transform: rotate(90deg); */
}

.email-flag > svg {
    margin: 0 10px;
    width: 20px;
    height: 20px;
}

 .email-menu__list-item > div {
    display: flex;
    align-items: center;
 }

.email-menu__list-item > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 50px;
}

/*end email styles*/

.visual-disabled {
    opacity: .4;
}

.visual-disabled:focus-within {
    opacity: 1;
}

header.MuiPaper-root.MuiPaper-elevation {
    background-color: #fff !important;
    color: #0b7b7f;
    box-shadow: 0px 9px 17px -2px rgb(0 0 0 / 7%) !important;
}
header.MuiPaper-root.MuiPaper-elevation a > .Logo-company-preview,
header.MuiPaper-root.MuiPaper-elevation a >.Logo-company-name {
    display: none;
}
header.MuiPaper-root.MuiPaper-elevation a > .Logo-company-name-double {
    display: block !important;
}
header.MuiPaper-root.MuiPaper-elevation a > .Logo-company-name-double-mobile {
    display: none !important;
}

.Header a > .Logo-company-preview,
.Header a > .Logo-company-name {
    display: block;
}

.Header a > .Logo-company-name-double {
    display: none;
}

.Header a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.table-row-color  {
    background: #e2f3f1;
    border-color: rgba(0, 0, 0, 0.03) !important;
}
.table-row-color th {
    background: #e2f3f1;
    border-color: rgba(0, 0, 0, 0.03);
}
.table-row-white {
    background-color: #ffffff !important;;
}
.table-row-red {
    background-color: #ffc8d3 !important;
}


/* Стили для липких ячеек таблицы */
.ant-table-cell-fix-right,
.ant-table-cell-fix-left {
  background: inherit !important;
}

/* Для четных строк */
.table-row-color .ant-table-cell-fix-right,
.table-row-color .ant-table-cell-fix-left {
  background: #e2f3f1 !important;
}

/* Для нечетных строк */
.table-row-white .ant-table-cell-fix-right,
.table-row-white .ant-table-cell-fix-left {
  background: #ffffff !important;
}

/* Для заголовков липких столбцов */
.ant-table-thead .ant-table-cell-fix-right,
.ant-table-thead .ant-table-cell-fix-left {
  background-color: var(--primary-color) !important;
}

.ant-checkbox-wrapper {
    color: white !important;
}

.black-text {
    height: 21px !important;
    max-height: 21px !important;
    color: black;
}
.ant-spin-dot-item {
    background-color: #0b4046;
}
.ant-spin-text { color: black; }
.table-row__incoming-email {
    background: #82a6dd57;
}

.table-row__outgoing-email {
    background: #8cd6ad4d;
}

.table-row-archive {
    opacity: 0.5;
}

.ant-tabs-tab.ant-tabs-tab-active  {
    border-bottom: none !important;
}

table.fixed {
    table-layout: fixed;
}
td.truncated {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ant-table-body table {
    background: #ffffff !important;
}
.table-row-blue {
    background-color: rgb(123, 104, 238, 0.3) !important;
}

.table-row-green {
    background-color: rgb(189, 236, 182, 0.3) !important;
}

.table-row-yellow {
    background-color: rgb(247, 242, 26, 0.3) !important;
}

.table-row-red {
    background-color: rgb(255, 73, 108, 0.3) !important;
}

.leaflet-routing-alt {
    display: none;
}

.leed-comment {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.top-table-actions {
    padding: 10px;
    background: #0b7b7f1f;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    align-items: center;
}

.typography-sums-preTasks {
    /*white-space: nowrap;*/
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    /*max-height: 50px;*/
}


.reset-margin .ant-form-item {
    margin-bottom: 10px !important;
}

.css-1ueqth8-MuiPaper-root-MuiCard-root, .css-uexerl-MuiPaper-root-MuiCard-root {
    box-shadow: none !important;
    border-radius: 10px !important;
}

.css-46bh2p-MuiCardContent-root:last-child {
    padding-bottom: 16px !important;
}

#custom-scroll::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

#custom-scroll::-webkit-scrollbar
{
    height: 10px;
    background-color: #F5F5F5;
}

#custom-scroll::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.5);
    background-color: #888888;
}


.react-resizable-handle {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: col-resize;
}

.dragHandler:hover {
    cursor: move;
    background-color: #ccc;
}
/*dashboard*/

.background-main-color {
    background-color: #0B7A7F;
}
.nav-item {
    color: white;
    background-color: #0B7A7F;
}
.tabs-white {
    color: #ffffff !important;
}

.leads-header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    font-weight: 600;
    font-size: 17px;
}

.leads-list {
    max-height: 400px;
    overflow-y: scroll;
    padding: 20px;
}

.leads-list__item {
    border-bottom: 2px solid #cecedf2b;
    padding: 10px 0 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.leads-list__name {
    font-weight: 500;
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.leads-list__date {
    color: #6b778c;
    font-weight: 500;
    font-size: 12px;
}


.leads-list-block > span {
    margin: 0 10px;
    font-weight: bold;
}

.lead-status {
    background-color: #f1f1f1;
    color: #b1b1b1;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 12px;
    letter-spacing: .4px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.lead-status.blue {
    background: #e1dfffb5;
    color: #6860f5;
}

.lead-status.orange {
    background: #ffc95424;
    color: #ffc954;
}

.lead-status.green {
    background: #81ce771f;
    color: #81ce77;
}

.lead-status.yellow {
    background: #c4d1031c;
    color: #b1bd07;
}

.lead-status.red {
    background: #ff000014;
    color: #ff0000;
}


/*dashpoard pre task*/

.pre-tasks {
    max-height: 410px;
    overflow-y: scroll;
    padding: 20px;
}

.pre-tasks__item:not(:last-child) {
    border-bottom: 2px solid #cecedf2b;
    padding: 10px 0 20px 0;
    display: flex;
    align-items: center;
}

.pre-tasks__item:last-child {
    padding-top: 10px;
    display: flex;
    align-items: center;
}

.pre-tasks__block {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
}

.pre-tasks__icon {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    border-radius: 50%;
    background-color: #eae8ff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: #6860f5;
    text-transform: uppercase;
    margin-right: 10px;
}

.pre-tasks__company-name {
    font-weight: 500;
    width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
    white-space: nowrap;
}

.pre-tasks__text {
    color: #6b778cbf;
    font-weight: 500;
    width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 10px;
}

.pre-tasks__price {
    font-weight: 700;
    font-size: 15px;
    width: 100px;
}

.ant-input-number {
    width: 100%;
}
/*custom form modal styles*/
.reset-margin-form .ant-form-item {
    margin-bottom: 5px;
}

.reset-margin-form .ant-form-item-label {
    padding: 0;
}

.MainTabSwitcher-RadioBtn {
    width: 85px !important;
}
.MainTabSwitcher-RadioBtn-Right {
    border-radius: 10px 0 0 10px !important;
}
.MainTabSwitcher-RadioBtn-Left {
    border-radius: 0 10px 10px 0 !important;
}

.calendar-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.calendar-mouth-day {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 200px;
    height: 160px;
    border: 1px solid var(--default-border-color);
    padding: 1px;
    margin-left: -1px;
    margin-bottom: -1px;
    background-color: var(--accent-color);
}

.calendar-mouth-day-today {
    height: 23px;
    width: 23px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    border-radius: 8px;
    padding: 3px 5px;
    background-color: #085b6087;
    line-height: 100%;
    transition: background-color .2s;
}
.calendar-mouth-day-today:hover {
    cursor: pointer;
    background-color: #099aa2;
    transition: background-color .2s;
}
.calendar-mouth-day-title {
    text-align: center;
    vertical-align: middle;
    height: 23px;
    width: 23px;
    display: table-cell;
    border-radius: 50%;
    background: none;
}
.calendar-mouth-day-title:hover {
    border-radius: 50%;
    background-color: #9fa2a4;
    cursor: pointer;
}
.calendar-mouth-weekday {
    display: flex;
    flex-direction: column;
    flex: 1 1 200px;
    height: 160px;
    border: 1px solid var(--default-border-color);
    padding: 1px;
    margin-left: -1px;
    margin-bottom: -1px;
    background-color: #e2f3f1a3;
}

.calendar-mouth-day-body {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    max-height: 150px;
    max-width: 100%;
    padding: 5px;
    overflow: hidden;
    height: 100%;
}
.calendar-mouth-day-more-tasks-btn {
    width: 100%;
    text-align: left;
    height: 26px;
    font-size: 12px;
    font-weight: 600;
    padding: 3px 3px 3px 3px;
    border-radius: 10px;
}
.week-day-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 950px;
    width: 100%;
    border: 1px solid #0B7A7F;
    padding: 1px;
    margin-left: -1px;
    margin-bottom: -1px;
    background-color: #ffffffff;
}
.week-day-wrapper-weekday {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 950px;
    width: 100%;
    border: 1px solid #0B7A7F;
    padding: 1px;
    margin-left: -1px;
    margin-bottom: -1px;
    background-color: #e2f3f1a3;
}
.week-day-header-wrapper {
    position: sticky;
    top: -25px;
    z-index: 500;
    display: flex;
    width: 100%;
    height: 50px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    font-weight: 600;
    background-color: rgba(206,228,229,1);
    border-bottom: 1px solid #0B7A7F;

}
.cell-time {
    width: 100%;
    position: relative;
    padding: 1px;
    height: 50px;
    max-height: 50px;
    min-height: 50px;
    border-bottom: 1px solid #0B7A7F;
}
.cell-time-flex {
    display: flex;
}
.cell-time-title {
    position: absolute;
    left: -40px;
    bottom: 39px;
    font-weight: 600;
}
.task-item-day-month {
    position: relative;
    height: 26px;
    top: 0;
    width: 100%;
    margin-top: 1px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 2px;
    border-radius: 10px;
    overflow: hidden;
    text-align: left;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.task-item-day-month-calendar-view-type-day {
    max-width: 200px;
}
.task-item-day-month-name {
    display: block;
    position: absolute;
    overflow: hidden;
    max-width: 210px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.task-item-day-month-dropdown-overlay-container {
    box-shadow: 7px 29px 113px 38px rgba(0,0,0,0.7);
    width: 250px;
    border-radius: 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.primary-bg-color {
    background-color: var(--primary-color) !important;
}

.primary-bg-color .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    border-top: 1px solid rgb(217,217,217,1);
    border-bottom: 1px solid rgb(217,217,217,1);
    border-right: 1px solid rgb(217,217,217,1);
    border-left: 1px solid white;
}
.primary-bg-color .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:hover {
    background-color: var(--primary-color);
}

.secondary-bg-color {
    background-color: var(--secondary-color) !important;
}
.icons-toolbar-color-black {
    color: rgba(0, 0, 0, 0.85)
}
.icons-toolbar-color-white {
    color: #ffffff !important;
}
.icons-toolbar-color-primary {
    color: var(--primary-color) !important
}

.task-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
}
.task-status-option {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-wrap: nowrap;
    padding: 0;
    font-weight: 500;
}

.total-rows-table {
    padding: 5px 0 5px 5px;
    float: right;
}

.customer-modal-steps {
    overflow: auto;
}
.customer-modal-steps::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px #c0dcd9;
    border-radius: 10px;
    background-color: #F5F5F5;
}

.customer-modal-steps::-webkit-scrollbar
{
    height: 10px;
    background-color: #F5F5F5;
}

.customer-modal-steps::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.5);
    background-color: #0b7b7f;
}

.tabs-scroll ::-webkit-scrollbar
{
    height: 10px !important;
    background-color: #ffffff;
}
.tabs-scroll ::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.5);
    background-color: #0b7b7f;
}
.global-style-drawer .ant-drawer-content-wrapper {
    width: 100% !important;
}

.chat-inactive .ant-drawer-content-wrapper {
    max-width: 50% !important;
}

.site-calendar-demo-card {
    width: 10px;
    border: 1px solid #f0f0f0;
}
.checkbox-group-calendar-sidebar {
    margin-top: 40px;
    color: black;
}
.checkbox-group-calendar-sidebar .ant-checkbox-wrapper {
    color: black !important;
    font-size: 16px;
}

.additional-btn-select {
    height: 32px;
    font-size: 10px;
    border-left: none
}

.edit-permissions-table .ant-table-sticky-holder {
    top: -26px !important;
}

.user-select-none {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}


.ant-layout-sider {
    transition: opacity 0.3s ease !important;
}

.ant-notification-notice {
    border-radius: 16px !important;
}

.reset-margin-form .ant-input[disabled],
.color-disabled .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.modal-card .ant-input[disabled],
.reset-margin-form .ant-input[disabled]::placeholder,
.modal-card .ant-input[disabled]::placeholder,
.reset-margin-form .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #000;
}

.tabs-scroll .ant-tabs-nav-list {
    overflow-y: scroll !important;
}
.tabs-scroll .ant-tabs-nav-operations {
    display: none !important;
}
.tabs-scroll .ant-tabs-tab.ant-tabs-tab-active {
   /* border: none !important;*/
    border-bottom: 0 !important;
}
.ant-tabs-ink-bar {
    border: none !important;
    background: none;
}
.btn-accept {
    margin: 0;
}
@media (max-width: 992px) {
    .btn-accept {
        margin-top: 10px;
    }
}

@media screen and (max-width: 1280px) {
    .task-item-day-month-dropdown-overlay-container {
        width: 220px !important;
    }
}
@media only screen and (max-width: 959px) {
    select,
    textarea,
    .ant-select,
    .react-tel-input .form-control,
    .ant-input {
        font-size: 16px !important
    }
    .ant-checkbox-wrapper span {
        color: black !important;
        text-wrap: none;
    }
    .ant-dropdown {
        z-index: 9000 !important;
    }
    .ant-input-wrapper .ant-input-search-button {
        height: 35px !important;
    }
}
/*commercial pre application styles*/

#MessageViewBody a {
    color: inherit;
    text-decoration: none
}

/* Стили для отключенных таблиц */
.ant-table-disabled,
.ant-table-disabled .ant-table {
  opacity: 0.65;
  filter: grayscale(0.35);
  user-select: none;
  background: #f5f5f5;
}
/* Убираем hover эффекты для отключенной таблицы */
.ant-table-disabled .ant-table-tbody > tr:hover > td,
.ant-table-disabled .ant-table-tbody > tr.ant-table-row:hover > td {
  background: inherit !important;
}
/* Скрываем пагинацию в disabled состоянии */
.ant-table-disabled .ant-pagination {
  display: none !important;
}

/* Стили для границ таблицы */
.ant-table-disabled .ant-table {
  border: none;
}
.ant-table-disabled .ant-table-container {
  border: none;
}

/* Переопределяем курсор для отключенной таблицы */
div:has(> .ant-table-disabled) {
  cursor: not-allowed !important;
}
