@import "devices-mixins";
@import "dashboard";
@import "menu-left-sider";
@import "header";
@import "tollbars";
@import "account";
@import "modals";
@import "calendar";

@include device-type(not-pc) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
