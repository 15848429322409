.task-page-tabs .ant-tabs-nav-list {
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  overflow: hidden;
}

.task-page-tabs .ant-tabs-tab {
  border-radius: 0;
  padding: 8px 16px;
  background-color: #fff;
}

.task-page-tabs .ant-tabs-tab-active {
  background-color: #0b7b7f;
  color: white;
}

.task-page-tabs .ant-tabs-tab {
  font-size: 16px;
  line-height: 38px;
  padding: 0 15px;
  margin: 0;
  border-left: 1px solid #d9d9d9;
  color: #595959;
}

.task-page-tabs .ant-tabs-tab:hover {
  color: #0b7b7f;
}

.task-page-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white;
}
