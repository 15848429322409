.person-dialog-wrapper {
    display: flex;
}

.person-dialog__main-info {
    margin-right: 20px;
    border: 1px solid #0b7b7f;
    height: 100%;
    height: auto;
    padding: 0 30px;
    border-radius: 5px;
    flex-direction: column;
    justify-content: space-around;
    display: flex;
}
.person-dialog__company, .person-dialog__phone {
    white-space: nowrap;
    overflow: hidden;
    max-width: 160px;
    text-overflow: ellipsis;
    padding: 5px 10px;
}

.person-dialog__company {
    color: #000;
    font-weight: 600;
    font-size: 18px;
}

.person-dialog__phone {
    color: #00000085;
    font-weight: 400;
    font-size: 13px;
    text-align: center;
}

.person-dialog__avatar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.person-dialog__avatar > img {
    width: 5em;
    height: 5em;
    object-fit: contain;
    border-radius: 50%;
}

.person-dialog__avatar > svg {
    width: 4em;
    height: 4em;
    fill: #0b7b7f;
}

.group-text-field {
    display: flex;
    align-items: center;
}

.group-text-field > svg {
    margin-left: 15px;
}
