
@import url('https://fonts.googleapis.com/css2?family=Tinos:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.WordSection1 {
    color: #000;
    font-family: 'Times New Roman', serif;
}

p.MsoNormal, li.MsoNormal, div.MsoNormal {
    margin:0in;
    font-size:12.0pt;
    font-family:"Times New Roman",serif;
}
p.MsoNoSpacing, li.MsoNoSpacing, div.MsoNoSpacing {
    margin:0in;
    font-size:11.0pt;
    font-family:"Times New Roman",sans-serif;
}

p.ConsPlusNormal, li.ConsPlusNormal, div.ConsPlusNormal {
    mso-style-name:ConsPlusNormal;
    margin:0in;
    text-autospace:none;
    font-size:11.0pt;
    font-family:"Times New Roman",sans-serif;
}
span.nowrap2 {
    mso-style-name:nowrap2;
    font-family:"Times New Roman",serif;
}
@page WordSection1 {
    size:595.3pt 841.9pt;
    margin:56.7pt 42.55pt 35.45pt 85.05pt;
}
div.WordSection1 {page:WordSection1;}