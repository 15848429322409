.LeftSider {
  background-color: #0b7a7f;

  @include device-type(pc) {
    width: 243px;
  }
  @include device-type(not-pc) {
    margin-left: -100%;
    pointer-events: none;
    opacity: 0;
    position: absolute;

    overflow: hidden;
    height: 100%;
    transition: all .2s !important;

    &.menu-active {
      --overflow-sizes: 100%;
      transition: all .2s !important;

      margin-left: 0;
      opacity: 1;
      pointer-events: all;

      background-color: rgba(65, 65, 65, 0.4);
      backdrop-filter: blur(7.5px);

      position: absolute;
      z-index: 100;
      width: var(--overflow-sizes) !important;
      min-width: var(--overflow-sizes) !important;
      max-width: var(--overflow-sizes) !important;

      .ant-layout-sider-children {
        border-radius: 0 27px 27px 0;
        width: fit-content;
        display: flex;
        align-items: center;
        overflow-y: auto;
      }

      .background-main-color {
        display: flex;
        height: 100% !important;
      }
    }

    @include device-type(not-pc) {
      .sub-menu-wrap {
        display: none;
      }
    }

    @include device-type(mb) {
      &.menu-active .background-main-color .ant-select {
        width: 140px !important;
      }
    }
  }
}
