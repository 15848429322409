.site-layout-background {
  @include device-type(pc) {
    padding: 34px;
  }
  @include device-type(not-pc) {
    padding: 12px;
    margin-left: 15px;
  }

  height: 100vh;
  overflow: auto;
  background-color: var(--accent-color) !important;
  background-image: url("../../images/mainBackground.svg") !important;
  border-radius: 27px;
  margin-bottom: 15px;
  margin-right: 15px;
  margin-top: 15px;

  .Dashboard-stats-chart-wrapper {
    display: block;
    @include device-type(pc) {
      padding: 0;
    }
    .MuiPaper-root {
      width: 100%;
    }
  }

  .Dashboard-toolbar {
    @include device-type(not-pc) {
      flex-direction: column;
      align-items: center;

      .ant-radio-group:first-child {
        margin-bottom: 15px;
      }
      .ant-radio-group:last-child {
        margin-left: 0 !important;
      }
    }

    .ant-radio-button-wrapper {
      @include device-type(mb) {
        font-size: 12px;
        line-height: 30px;
        height: 30px;
        padding: 0 7px;
      }
      @include device-type(tb) {
        font-size: 14px;
      }
    }
  }

  .Dashboard-stats-item.LeedsStatTable, .Dashboard-stats-chart-wrapper {
    @include device-type(pc) {
      width: 35%;
      min-width: 35%;
      max-width: 35%;
    }
    @include device-type(not-pc) {
      width: 100%;
      max-width: none;
      min-width: auto;
    }
  }

}

.Dashboard-layout-stats-content {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  @include device-type(pc) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  @include device-type(not-pc) {
    flex-direction: column;
    align-items: center;
  }

  .Dashboard-stats-item {
    width: 100%;
  }
  .Dashboard-stats-item-sales-chart {
    width: 100% !important;
  }
}

.Dashboard-stats-grid {
  @include device-type(pc) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    width: 100%;
  }
  @include device-type(not-pc) {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  .Dashboard-stats-grid-item {
    display: flex;
    width: 100%;
    position: relative;
    .MuiPaper-root {
      width: 100%;
    }
  }

  .TaskProgress-item {
    display: flex;
    justify-content: space-between;
  }

  .TaskProgress-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    @include device-type(pc) {
      --sizes: 56px;
    }
    @include device-type(not-pc) {
      --sizes: 46px;
    }

    width: var(--sizes);
    min-width: var(--sizes);
    height: var(--sizes);
    min-height: var(--sizes);

    color: white;

    &.avatar-yellow {
      background-color: #ffc954;
    }
    &.avatar-red {
      background-color: #fb7268;
    }
    &.avatar-blue {
      background-color: #3487ff;
    }
  }
}
