@import "./theme.css";
@import '~react-phone-input-2/lib/style.css';

.chat-not-exist .task-planner {
    flex: auto;
    width: 100%;
    max-width: 100%;
}

.upload-container .ant-upload-list {
    display: flex;
    flex-wrap: wrap;
}

.Chat-CreateChatButton {
    margin-left: auto;
}

::-webkit-scrollbar {
    width: 12px;
}


::-webkit-scrollbar-thumb {
    background: #0000002e;
}

.css-1m35x9a-MuiPaper-root-MuiAppBar-root {
    z-index: 30 !important;
}

.css-llf1rc-MuiPaper-root-MuiDrawer-paper {
    z-index: 29 !important;
}

.fixed-width-label {
    margin-bottom: 3px !important;
}

.fixed-width-label > div > label {
    width: 80px;
}

.fixed-width-label.tab > div > label {
    width: 90px;
}

.fixed-width-label.tab-1 > div > label {
    width: 110px;
}

.fixed-width-label.tab-2 > div > label {
    width: 30px;
}

.modal-card .ant-card-head-title {
    font-size: 14px;
    padding: 10px 0;
}

.modal-card .ant-card-body {
    padding: 14px;
}

.modal-card .ant-card-head {
    background: #0b7b7f;
    color: #fff;
}

/*bill template styles*/

a.comment-indicator:hover + div.comment {
    background: #ffd;
    position: absolute;
    display: block;
    border: 1px solid black;
    padding: 0.5em
}

a.comment-indicator {
    background: red;
    display: inline-block;
    border: 1px solid black;
    width: 0.5em;
    height: 0.5em
}

div.comment {
    display: none
}

.react-tel-input .form-control {
    width: 100% !important;
}
