.popover-title {
    background-color: #0b7b7f;
    color: #ffffff;
    max-height: 100px;
    min-width: 300px;
    padding: 10px;
    font-size: 17px;
}
.ant-popover-title {
    padding: 0 !important;
    border-bottom: none !important;
}
