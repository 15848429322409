.ed-content{
    .table{
        display:table;
        margin:.9em auto
    }
    .table table{
        border-collapse:collapse;
        border-spacing:0;
        height:100%;
        width:100%
    }
    .table table td, .table table th{
        min-width:2em;
        padding:.4em
    }
    .table table th{
        font-weight:700
    }
    [dir=rtl] .table th{
        text-align:right
    }
    [dir=ltr] .table th{
        text-align:left
    }
    .table .ck-table-resized{
        table-layout:fixed
    }
    .table table{
        overflow:hidden
    }
    .table td, .table th{
        overflow-wrap:break-word;
    }
    blockquote{
        border-left:5px solid #ccc;
        font-style:italic;
        margin-left:0;
        margin-right:0;
        overflow:hidden;
        padding-left:1.5em;
        padding-right:1.5em
    }
    [dir=rtl] blockquote{
        border-left:0;
        border-right:5px solid #ccc
    }
    .text-tiny{
        font-size:.7em
    }
    .text-small{
        font-size:.85em
    }
    .text-big{
        font-size:1.4em
    }
    .text-huge{
        font-size:1.8em
    }
    .marker-yellow{
        background-color:var(--ck-highlight-marker-yellow)
    }
    .marker-green{
        background-color:var(--ck-highlight-marker-green)
    }
    .marker-pink{
        background-color:var(--ck-highlight-marker-pink)
    }
    .marker-blue{
        background-color:var(--ck-highlight-marker-blue)
    }
    .pen-red{
        background-color:initial;
        color:var(--ck-highlight-pen-red)
    }
    .pen-green{
        background-color:initial;
        color:var(--ck-highlight-pen-green)
    }
    hr{
        background:#dedede;
        border:0;
        height:4px;
        margin:15px 0
    }

    .image{
        clear:both;
        display:table;
        margin:.9em auto;
        min-width:50px;
        text-align:center
    }
    .image img{
        display:block;
        margin:0 auto;
        max-width:100%;
        min-width:100%
    }
    .image-inline{
        align-items:flex-start;
        display:inline-flex;
        max-width:100%
    }
    .image-inline picture{
        display:flex
    }
    .image-inline img, .image-inline picture{
        flex-grow:1;
        flex-shrink:1;
        max-width:100%
    }
    .image.image_resized{
        box-sizing:border-box;
        display:block;
        max-width:100%
    }
    .image.image_resized img{
        width:100%
    }
    .image.image_resized>figcaption{
        display:block
    }
    .image-style-block-align-left, .image-style-block-align-right{
        max-width:calc(100% - var(--ck-image-style-spacing))
    }
    .image-style-align-left, .image-style-align-right{
        clear:none
    }
    .image-style-side{
        float:right;
        margin-left:var(--ck-image-style-spacing);
        max-width:50%
    }
    .image-style-align-left{
        float:left;
        margin-right:var(--ck-image-style-spacing)
    }
    .image-style-align-center{
        margin-left:auto;
        margin-right:auto
    }
    .image-style-align-right{
        float:right;
        margin-left:var(--ck-image-style-spacing)
    }
    .image-style-block-align-right{
        margin-left:auto;
        margin-right:0
    }
    .image-style-block-align-left{
        margin-left:0;
        margin-right:auto
    }
    p+.image-style-align-left, p+.image-style-align-right, p+.image-style-side{
        margin-top:0
    }
    .image-inline.image-style-align-left, .image-inline.image-style-align-right{
        margin-bottom:var(--ck-inline-image-style-spacing);
        margin-top:var(--ck-inline-image-style-spacing)
    }
    .image-inline.image-style-align-left{
        margin-right:var(--ck-inline-image-style-spacing)
    }
    .image-inline.image-style-align-right{
        margin-left:var(--ck-inline-image-style-spacing)
    }

    ol{
        list-style-type:decimal
    }
    ol ol{
        list-style-type:lower-latin
    }
    ol ol ol{
        list-style-type:lower-roman
    }
    ol ol ol ol{
        list-style-type:upper-latin
    }
    ol ol ol ol ol{
        list-style-type:upper-roman
    }
    ul{
        list-style-type:disc
    }
    ul ul{
        list-style-type:circle
    }
    ul ul ul, ul ul ul ul{
        list-style-type:square
    }
    .media{
        clear:both;
        display:block;
        margin:.9em 0;
        min-width:15em
    }
    .image>figcaption{
        background-color:var(--ck-color-image-caption-background);
        caption-side:bottom;
        color:var(--ck-color-image-caption-text);
        display:table-caption;
        font-size:.75em;
        outline-offset:-1px;
        padding:.6em;
        word-break:break-word
    }
    table.table-bordered {
        border-collapse: collapse;
        border-spacing: 0;
        &,
        td,
        th {
            border: 1px solid #000
        }
    }
}

.ck-editor {   
    table.table-bordered{
        &,
        th,
        td {
            border: 1px solid #000
        }
    }
}