.technical-work {
  .page-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flex {
    display: flex;
  }
  .row {
    flex-direction: row;
  }
  .no-wrap {
    flex-wrap: nowrap;
  }
  .col {
    display: flex;
    flex-direction: column;
  }
  .title {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-top: 20px;
    font-weight: 600;
    line-height: 1.5;
    -webkit-background-clip: text;
  }
  .image {
    max-width: 650px;
    height: auto;
    max-height: 600px;
  }

  p {
    font-size: 18px;
    color: #555;
  }
  .retry-btn {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .retry-btn button {
    margin-top: 60px;
    background-color: #0b7a7f;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s ease;
  }

  .retry-btn button:hover {
    background-color: #0b7f85;
  }

  @media (max-width: 768px) {
    .row {
      flex-direction: column;
      gap: 30px;
    }

    .image {
      max-width: 90%;
      max-height: 400px;
    }

    .title {
      font-size: 18px;
      margin: 0 auto;
    }
    .col {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .retry-btn button {
      padding: 10px 20px;
      font-size: 16px;
      width: 50%;
    }
  }

  @media (max-width: 480px) {
    .page-wrap {
      padding-left: 10px;
      padding-right: 10px;
    }

    .title {
      font-size: 16px;
      margin: 0 auto;
    }
    .col {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .retry-btn button {
      padding: 14px 20px;
      font-size: 14px;
      width: 90%;
      margin-top: 40px;
    }
  }
}
