
.message-input-wrapper {
    padding: 20px;
    width: 100%;
    position: absolute;
    bottom: 0;
}

.message-input-wrapper .ant-input .ant-input-lg {
    border-radius: 10px !important;
}
