.sub-menu-wrap {
    position: absolute;
    left: 235px;
    height: 100vh !important;
    top: 0;
    transition: background .3s, width .3s cubic-bezier(.2,0,0,1) 0s !important;
}

.sub-menu-nav-wrap {
    color: #ffffff !important;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.sub-menu-nav-wrap:hover {
    color: #b2fcff !important;
}

.sub-menu-block {
    left: 235px;
    height: 100%;
    position: fixed;
    top: 0 !important;
    z-index: 5000;
    width: min-content;
    min-width: 120px;
    background: #c0dcd9;
    transition: background .3s, width .3s cubic-bezier(.2,0,0,1) 0s !important;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px 0 #00000014, 0 9px 28px 8px #0000000d !important;
    transform-origin: 0 0;
}


.sub-menu-block li {
    margin: 0px !important;
    color: #232928 !important;
    background: #c0dcd9 !important;
}

.sub-menu-block li:hover {
    background: #ffffff !important;
    color: #0b7b7f !important;
}

.sub-menu-context-menu {
    height: 20px !important;
}
.sub-menu-context-menu .ant-menu {
    width: 200px;
    background: #ffffff !important;

}