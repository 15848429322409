.CalendarWrapper {
  background-color: transparent;
  color: black;
  min-height: 100%;
  display: flex;
  width: 100%;

  @include device-type(pc) {
    flex-direction: row;
    padding: 20px 0 10px 0;
  }
  @include device-type(not-pc) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .Calendar-full-wrapper {
    position: relative;
    @include device-type(pc) {
      margin-left: 20px;
    }
    @include device-type(not-pc) {
      margin-left: 0;
    }
    @include device-type(mb) {
      display: none;
    }
  }

  .Calendar-widget-wrapper {
    color: black;
    min-height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--accent-color);
    border: 1px solid var(--default-border-color);
    border-radius: 10px;

    @include device-type(pc) {
      width: 250px;
      min-width: 250px;
    }
    @include device-type(not-pc) {
      width: 100%;
      max-width: 450px;

      .react-calendar__month-view__weekdays__weekday,
      .react-calendar__month-view__days__day--neighboringMonth {
        color: var(--gray-scale-color);
      }
      .react-calendar__tile--now, .react-calendar__tile--active {
        abbr {
          position: relative;
          z-index: 1;
        }
      }
      .react-calendar__tile--now {
        color: var(--primary-color-light);
        background-color: var(--background-gray-scale-color);
        &.react-calendar__tile--active {

        }
      }
      .react-calendar__tile--active {
        position: relative;
        color: var(--accent-color) !important;
        background-color: var(--accent-color);

        &:after {
          content: '';
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 0;
          border-radius: 100%;
          width: 30px;
          height: 30px;
          background-color: var(--primary-color-scale-light);
        }
      }
    }
  }

  &>.MuiContainer-root {
    padding-top: 20px;
    border: 1px solid var(--default-border-color);
    border-radius: 10px;
  }
}

// Tasks section in mobile view
@include device-type(not-pc) {
  .TasksSection {
    padding-top: 5px;
    margin-top: 5px;
    border-top: 1px solid var(--default-border-color);
  }

  .TasksSection-task-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 10px;
    min-height: 40px;
    position: relative;
    padding: 5px 0;
    align-items: center;
    border-radius: 6px;

    &:active {
      border: 1px solid var(--primary-color-light);
    }

    .TaskSection-task-item-overflow {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
      opacity: .1;
      border-radius: 6px;
    }

    .TaskSection-task-item-color {
      position: absolute;
      z-index: 1;
      display: block;
      border-radius: 6px;
      width: 5px;
      min-height: 40px;
      height: 70%;
    }
    .TaskSection-task-item-body {
      position: relative;
      z-index: 1;
      margin-left: 15px;
    }

    .TaskSection-task-header,
    .TaskSection-task-footer {
      font-weight: 500;
    }

    .TaskSection-task-header {
      font-size: 14px;
      line-height: 120%;
    }
    .TaskSection-task-footer {
      font-size: 12px;
      color: #929292;
      margin-top: 5px;
    }
  }

  .TaskSection-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0 15px 0;

    .TaskSection-task-create-button {
      border-radius: 100%;
    }
  }

  .TasksSection-date {
    display: flex;
    gap: 5px;
    margin-top: 5px;

    .TaskSection-date-month-day, .TaskSection-date-year {
      margin: 0;
      font-weight: 500;
      font-size: 15px;
      line-height: 120%;
    }
  }

  .MiniCalendar-tile {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .MiniCalendar-tile-event-exists {
      --sizes: 4px;
      position: absolute;
      width: var(--sizes);
      height: var(--sizes);
      display: block;
      border-radius: 100%;
      background-color: var(--blue-light);
      bottom: 5px;
    }
  }
}

.EditForm-priority-field {
  display: flex;
  @include device-type(pc) {
    flex-direction: row;
    justify-content: flex-end;
  }
  @include device-type(not-pc) {
    flex-direction: column;
    align-items: flex-end;
  }
}

@include device-type(not-pc) {
  .CalendarSidebar-select-staff-col {
    margin-bottom: 20px;
  }
}

.create-modal-in-drawer {
  @include device-type(not-pc) {
    .ant-drawer-header,
    .ant-drawer-wrapper-body,
    .ant-drawer-content {
      border-radius: 12px 12px 0 0;
    }

    .ant-input {
      border-radius: 4px;
      border: none;
      background-color: var(--mobile-bluelight-field-bg-color);
      margin-top: 8px;
    }
  }

  .ant-drawer-content-wrapper {
    @include device-type(pc) {
      max-height: 80vh;
    }
    @include device-type(not-pc) {
      top: unset !important;
      .ant-drawer-body {
        display: flex;
        flex-direction: column;
        .CalendarTaskModal-create-mobile-button {
          border: none;
          border-radius: 20px;
          width: 80%;
          color: white;

          position: relative;
          bottom: 15px;
          left: 50%;
          transform: translateX(-50%);
          background-color: var(--primary-color);

          &:disabled {
            background-color: var(--mobile-graydark-bg-color);
          }
        }

        .ant-tabs {
          width: 100%;
          //height: 70vh;
          overflow-y: auto;
          height: 100%;
          .ant-tabs-content {
            height: 100%;
          }

          .chat-wrapper {
            height: 100% !important;
            min-height: 65vh;
          }
          .chat-content {
            display: grid;
            grid-template-rows: 1fr max-content;
          }
          .message-input-wrapper {
            position: relative;
            z-index: 1;
            padding: 0 20px 20px;
            input {
              font-size: 16px !important;
            }
          }
        }
      }

      .ant-select-selector {
        border: none;
        border-radius: 4px;
        background-color: var(--mobile-bluelight-field-bg-color);
      }

      .BodyCalendarTaskDrawer {
        margin-bottom: 20px;
      }

      .ant-spin-nested-loading, .ant-spin-container {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }
  }

  @include device-type(not-pc) {
    .Chat-CreateChatButton-wrapper-row .Chat-CreateChatButton {
      margin-right: auto;
    }
  }
}
@include device-type(pc) {
  .chat-exist {
    .Chat-body, .Chat-body-content {
      height: 100%;
    }

    .Chat-body-content {
      padding: 20px;
    }
  }
}
@include device-type(not-pc) {
  .chat-exist .chat-column {
    height: 70vh;
  }

  .chat-exist .Chat-body {
    display: flex;
    height: 100%;
    padding: 20px 20px 0 20px;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    .Chat-body-content {
      display: flex;
      height: 100%;
      width: 100%;
      flex-flow: column;
      overflow: auto;
    }
  }

  .CalendarTaskModal-chat-tab {
    height: 100%;
    display: grid;
  }

  .headerCalendarTaskDrawer, .headerCalendarTaskDrawer .main-info {
    min-height: fit-content;
  }
}
