.row-dragging {
  border: 1px solid #ccc;
  z-index: 9999;
  display: table;
  width: 100%;
  table-layout: fixed;

  td {
    visibility: visible !important;
    display: table-cell;
    box-shadow: none !important;
  }

  .drag-visible {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
  }

  td:not(.drag-visible) {
    width: calc(50% - 25px);
    overflow: hidden;
  }
}