
.mainCard {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 25px;
    min-height: 222px;
    -webkit-box-shadow: -5px 22px 30px -19px rgba(107,  107,  107, 0.5);
    -moz-box-shadow: -5px 22px 30px -19px rgba(107,  107,  107, 0.5);
    box-shadow: -5px 22px 30px -19px rgba(107,  107,  107, 0.5);
}

.mainText {
    color: black;
    font-size: 15px;
    font-weight: 500;
}
.secondText {
    font-size: 14px;
    color: #91929E;
    font-weight: 500;
}
.valueText {
    color: black;
    font-size: 14px;
    font-weight: 500;
}

.colorBlock {
    width: 35px;
    height: 18px;
    margin-top: 2px;
    border-radius: 8px;
}