.incoming-call {
    position: fixed;
    right: 10px;
    bottom: 50px;
    padding: 15px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2a2a36;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.incoming-call-wrapper {
    display: flex;
}

.incoming-call__avatar > svg {
    width: 1.5em;
    height: 1.5em;

}

.incoming-call__info {
    margin: 0px 15px;
}

.incoming-call__name {
    font-size: 17px;
    font-weight: 600;
}

.incoming-call__type-call {
    font-size: 12px;
    color: #ffffffb8;
}

.incoming-call__action-btns {
    position: relative;
    display: flex;
}
.incoming-call__action-btn {
    animation: phone 1s cubic-bezier(0.12, 0.7, 0.74, 0.71) infinite alternate-reverse;
    display: flex;
    align-items: center;
    justify-content: center;
}

.incoming-call__action-btn.accept {
    margin-right: 15px;
}


.incoming-call__action-btn.accept > svg {
    fill: #fff;
    border-radius: 50%;
    padding: 5px;
    background: #2dd356;
    width: 1.3em;
    height: 1.3em;
    z-index: 2;
}

.incoming-call__action-btn.decline > svg {
    fill: #fff;
    border-radius: 50%;
    padding: 5px;
    background: #fd3b30;
    width: 1.3em;
    height: 1.3em;
    z-index: 2;
}




@keyframes phone {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
}