.Header {
  @include device-type(not-pc) {
    margin-left: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .Header-button-badge .ant-badge-count {
      z-index: 1;
    }

    .MobileMenu {
      display: flex;
      align-items: center;
      @include device-type(tb) {
        padding-right: 5px;
      }

      &:active {
        opacity: .6;
      }

      .MobileMenu-button {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
      }
    }

    .Logo-company-name-double-mobile {
      margin-bottom: -3px;
    }

    .ant-btn-icon-only.ant-btn-sm {
      border-radius: 5px;
    }
  }

  @include device-type(tb) {
    gap: 40px;
  }
  @include device-type(mb) {
    gap: 15px;
  }

  .Header-body {
    background-color: var(--accent-color);
    border-radius: 27px;
    @include device-type(pc) {
      padding: 0 50px;
    }
    @include device-type(not-pc) {
      width: 100%;
    }
    @include device-type(tb) {
      padding: 0 25px;
    }
    @include device-type(mb) {
      padding: 0 10px;
    }

    .Header-buttons-toolbar {
      .Header-button-col + .Header-button-col {
        @include device-type(pc) {
          margin-left: 12px;
        }
        @include device-type(tb) {
          margin-left: 7px;
        }
        @include device-type(mb) {
          margin-left: 4px;
        }
      }
    }
  }
}

.ant-layout-header {
  height: 64px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 64px;
  margin-top: 15px;
  margin-right: 15px;
}
