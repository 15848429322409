
.headerCalendarTaskDrawer {
    background-color: #0b7b7f;
    min-height: 300px;
    border-bottom-right-radius: 20px;
    padding: 0;
}

.headerCalendarTaskDrawer .ant-form-item-explain {
    color: #ffffff !important;
}

.headerCalendarTaskDrawer .ant-form-vertical .ant-form-item-label > label {
    color: #ffffff !important;
}

.main-info {
    min-height: calc(300px - 39px);
    padding: 30px;
}

.gutter-top {
    margin-top: 20px;
}
